ul, li {
    list-style:none;
}

ol, ul, dl {
    margin: 0;
    padding:0;
}

.heroContent h1 {
    font-size:700%;
    display:inline;
    color:white;

}

.heroSubtitle {
    color: white;
    font-size: 30px;
    position:relative;
    display:flex;
    gap:10px;
    align-items:flex-start;
}

.hero_textcontainer {
    display: flex;
    align-items: center;
    position:relative;
}

.dropping-texts {
    position: relative;
    font-size: 40px;
    padding: 0px 10px;
    padding:0px 30px;
    display:flex;
    flex-direction:column;
}

    .dropping-texts ul {
        left: 0;
        width: 100%;
        background-color: #14f3db;
        border-radius: 10px;
        padding-top: 40px;
        transform: translateY(-30px);
        overflow: hidden;
    }



        .dropping-texts ul li:hover {
            transition: filter 0.5s;
            filter: brightness(80%);
        }

    .dropping-texts::after{
        content:'?';
        width:20px;
        height:20px;
        position:absolute;
        left: 105%;
    }

    .dropping-texts li {
        padding: 5px 20px 5px 0px;
        font-size: 80%;
        position: relative;
        display: flex;
        background-color: #14f3db;
        padding: 10px 20px;
        box-sizing: border-box;
        border-radius: 10px;
        opacity:80%;
    }



    .dropping-texts li, .selected-span {
        cursor: pointer;
    }

.selected-span {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    z-index: 1;
    border-radius: 10px;
}

.home_li_before {
    font-size: 70%;
    display:flex;
    justify-content:center;
    align-items:center;
    width:40px;
    transform: translateY(10px);
}



    .dropping-texts span, .dropping-texts ul {
        transition: height 1s;
    }

    .heroSubtitle h2 {
        font-size: 40px;
        cursor: pointer;
    }

 .heroSubtitle svg {
     width:40px;
     height:40px;
 }

.heroContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0;
    gap: 10px;
    z-index: 10;
    text-align: center;
    width: 100%;
}

.socialBlock {
    position:absolute;
    display: flex;
    align-self: flex-end;
    flex-direction: column;
    gap: 10px;
    font-size: 150%;
    z-index: 99;
    right: 30px;
    bottom: 20px;
}

.lang {
    position: absolute;
    left: 30px;
    bottom: 20px;
    display: flex;
    align-self: flex-end;
    flex-direction: column;
    font-size: 150%;
    z-index: 999;
}

.socialBlock ul {
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-content:center;
}

.heroBackground {
    width: 100%;
    height: 100%;
    margin: 0;
    background: transparent;
    display: flex;
    justify-content: space-between;
    position: absolute;
    box-sizing:border-box;
    max-width:100vw;
}

.HomeButton {
    font-size: 150%;
    display:flex;
    justify-items:center;
    align-items:center;
    margin-left:60px;
    margin-top:10px;
    height:100%;
}


.IconHomeButton {
    color: #00ffff;
    transition: 1s;
}
    .IconHomeButton:hover {
        color: white;
        transform: translate(15px) scale(1.5);
    }


.TextHomeButton {
    color: white;
    width: 0;
    padding:0;
    margin:0;
    overflow: hidden;
    display: inline-block;
    white-space: nowrap;
    transition: width 1.5s, opacity 1.5s;
}


.HomeButton:hover .TextHomeButton {
    animation: home_icon_animation 1.5s forwards;
}

@keyframes home_icon_animation {
    0% {
        width: 0;
    }

    100% {
        width: 120px; 
        max-width:fit-content
    }
}

canvas {
    display: block;
    vertical-align: bottom;
}
/* ---- tsparticles container ---- */
#tsparticles {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #323031;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
}
    .logo img {
        width:80px;
    }