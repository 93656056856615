/* About Page */

.aboutContainer {
    display: flex;
    gap: 70px;
    align-content:center;
}

.videoContainer {
max-width:1000px;
margin:auto;
width:100%;
height:auto;
}


.color {
    color: #14f3db;
}

.pragLink {
    color: #f1712e;
    font-weight:bold;
}

.aboutImg {
    width: 1200px;
    align-content: center;
    background-image: url("../../assets/antoine.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.aboutText {
    display: flex;
    flex-direction: column;
    padding-bottom:20px;
}

.aboutDescription h2 {
    padding: 0px !important;
}

.aboutDescription {
    display: inline-flex;
    gap: 20px;
    flex-direction: column;
    padding-bottom: 40px;
}

    .aboutDescription h4 {
        font-size: 150%;
        color:#14f3db;
    }

.educationAside h4 {
    color: #14f3db;
}

.underligned {
    border-bottom: solid 2px #14f3db;
    padding-bottom: 40px;
}

    .underligned a:hover {
        color: #9DEFF4;
    }

.aboutContainerText {
    display: flex;
    padding-top: 30px;
    gap: 100px;
}


.aboutContainerDescription {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.aboutButton {
    display: flex;
    gap: 20px;
    align-content: center;
    margin-top: 80px;
}

.SocialIcon {
    font-size: 200%;
    color:black;
}
    .SocialIcon:hover {
        color: #14f3db;
    }

    .aboutSocial {
    display: flex;
    gap: 20px;
    align-content: center;
}

        .aboutSocial svg {
            color: white;
        }


    .aboutSocial::before {
        display: flex;
        align-self: center;
        background-color: #14f3db;
        content: '';
        height: 2px;
        margin-right: 23px;
        width: 80px;
    }

.aboutButton li {
    justify-content:center;
    display:flex;
    align-content:center;
    align-items:center;
}
.aboutButton ul {
    font-size: 150%;
    align-self: center;
}

.aboutBtn {
    margin: 0 !important;
    color: white;
    border: none;
    font: inherit;
    outline: inherit;
}

.aboutBtn:hover {
    background-color: #14f3db;
    color:white;
    transform: translateX(10px);
    transition: 0.4s ease-out;
}

/* EDUCATION SECTION */

.education_main {
    display: flex;
    justify-content: space-between;
    gap: 150px;
    padding: 0 100px;
}

.education_container {
    display: flex;
    flex-direction: column;
    gap: 40px;
    flex-grow: 1;
    justify-content: space-around;
}

.education_card {
    padding: 10px 10px 10px 50px;
    margin-left: -5px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    background: white;
    border-radius: 15px;
    border: 3px #14f3db solid;
}

    .education_card:hover {
        cursor: pointer;
        transform: translateY(-20px);
    }

    .education_card a:hover {
        color: #9DEFF4;
    }

    .education_card:hover {
        transform:translate(20px, -10px)
    }

        .education_card:hover h3 {
            color: #14f3db !important;
        }

        .education_card h3 {
            color: black !important;
        }


.educationAside {
    display: flex;
    flex-direction: column;
    padding: 40px;
    width: 500px;
    background: #1C1C1C;
    border-radius: 15px;
    gap: 30px;
    flex-grow: 1;
    color: white;
    height: 750px;
    box-shadow: -0.3rem 0 1rem #000;
}


    .educationAside h3 {
        font-size: 200%;
        text-align: center;
    }

    .educationAside h4 {
        font-size: 120%;
    }

    .educationAside p {
        font-size: 130%;
    }

.educationIcon {
    color: white;
    font-size: 3.5em;
    width: auto;
    margin-top: 60px;
    animation: 1s alternate-reverse infinite;
    animation-name: Arrow;
}

.responsive {
    display:none;
    color:black;
    text-decoration:none;
}

@keyframes Arrow {
    from {
        transform: rotate(30deg);
        margin-left: 50px;
    }

    to {
        transform: rotate(0deg);
        margin-left: 0px;
    }
}

@keyframes educ {
    from {
        transform: translateX(-40px);
        opacity: 0;
    }

    to {
        transform: translateX(0px);
        opacity: 1;
    }
}

#mastertwo::before, #masterone::before, #ceasure::before, #bachelor::before, #dut::before, #bac::before {
    position: absolute;
    border-top: 3px #14f3db solid;
    padding-top: 10px;
    height: 2px;
    margin-left: -110px;
    width: 80px;
    color: white;
}

#mastertwo::before {
    content: '2019 - 2020';
}

#masterone::before {
    content: '2017 - 2018';
}

#ceasure::before {
    content: '2018 - 2019';
}

#bachelor::before {
    content: '2016 - 2017';
}

#dut::before {
    content: '2014 - 2016';
}

#bac::before {
    content: '2014';
}

.ReviewContainer {
    background: #1C1C1C;
    padding: 20px 0px;
    border-radius: 25px;
    border: 5px solid #14f3db;
    box-shadow: -0.3rem 0 1rem #000;
    margin-top:70px;
}


.ReviewContainer h2 {
    text-align: center;
}

.ReviewList {
    display: flex;
    align-content: center;
    justify-content: center;
    gap: 35px;
    flex-wrap:wrap;
}

.reviewResult {
    text-align:center;
}

.reviewText {
align-self:center;
justify-content:flex-start;

}

    .reviewText h2 {
        padding: 0 !important;
        color: #14f3db !important;
        text-align:left;
    }



.reviewResult {
    display:flex;
    justify-content:center;
    gap:50px;
}

.ReviewList button {
    height: 56px;
    width: 56px;
    border-radius: 50%;
    position: relative;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: none;
}

.face {
    height: 20px;
    position:relative;
    display:flex;
    flex-direction:column;
    width:40px;
    gap:3px;
}
.ReviewContainer button:hover .face {
    transform: translateX(-4px);
    transition: transform linear 0.2s;
}


.eyes {
    display:flex;
    justify-content:space-between;
    align-content:center;
}

.eyes span {
    display:block;
    height:8px;
    width:8px;
    border-radius:50%;
    background:black;
    animation: animEyes linear infinite 7s;
}

.mouth {
    justify-content:center;
    width:17px;
    height:7px;
    border:4px solid black;
    align-self:center;

}



.ReviewContainer button:nth-child(1) {
    background: radial-gradient(93.75% 96.51% at 6.25% 97.32%, #FF462E 0%, #FF7C50 100%);
}

    .ReviewContainer button:nth-child(1) .face {
        margin-top: 33px;
        margin-left: 12px;
    }

    .ReviewContainer button:nth-child(1) .mouth {
        border-bottom: 0px;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
    }


.ReviewContainer button:nth-child(2) {
    background: radial-gradient(93.75% 96.51% at 6.25% 97.32%, #FE6430 0%, #FFAA51 100%);
}

    .ReviewContainer button:nth-child(2) .face {
        margin-top: 20px;
        margin-left: 12px;
    }

    .ReviewContainer button:nth-child(2) .mouth {
        border-bottom: 0px;
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
    }


.ReviewContainer button:nth-child(3) {
    background: radial-gradient(93.75% 96.51% at 6.25% 97.32%, #FFAD45 0%, #FFD27E 100%);
}

    .ReviewContainer button:nth-child(3) .face {
        margin-top: 10px;
        margin-left: 12px;
        gap: 5px;
    }

    .ReviewContainer button:nth-child(3) .mouth {
        border-bottom: 0px;
        border-left: 0px;
        border-right: 0px;
    }


.ReviewContainer button:nth-child(4) {
    background: radial-gradient(93.75% 96.51% at 6.25% 97.32%, #FFC82F 0%, #FFE253 100%);
}

    .ReviewContainer button:nth-child(4) .face {
        margin-top: 0px;
        margin-left: 12px;
    }

    .ReviewContainer button:nth-child(4) .mouth {
        border-top: 0px;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
    }


.ReviewContainer button:nth-child(5) {
    background: radial-gradient(93.75% 96.51% at 6.25% 97.32%, #FFE83A 0%, #FFF468 100%);
}
    .ReviewContainer button:nth-child(5) .face {
        margin-top: -10px;
        margin-left: 12px;
    }

    .ReviewContainer button:nth-child(5) .mouth {
        border-top: 0px;
        background: black;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        height: 10px;
    }


@keyframes animEyes {
    0% {
        transform: scaleY(1);
    }

    5% {
        transform: scaleY(1);
    }
    10% {
        transform: scaleY(1);
    }
    15% {
        transform: scaleY(0);
    }
    20% {
        transform: scaleY(1);
    }

    50% {
        transform: scaleY(1);
    }

    55% {
        transform: scaleY(0);
    }

    60% {
        transform: scaleY(1);
    }

    80% {
        transform: scaleY(1);
    }

    85% {
        transform: scaleY(0);
    }

    90% {
        transform: scaleY(1);
    }

    95% {
        transform: scaleY(0);
    }

    100% {
        transform: scaleY(1);
    }


}