html {
    overflow-x: hidden;
}

#root {
    background-color: #323031;
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    text-decoration:none;
    }

input:focus {
    outline: none;
    color:white;
}

.button {
    padding: 10px 40px;
    border-radius: 15px;
    background: #14f3db;
    align-items: center;
    text-align: center;
    font-size: 120%;
    margin-top: 40px;
    display: inline-block;
    width: fit-content;
}

.button:hover {
    background-color: #00ab98;
    color: white;
    transform: translateX(10px);
    transition: 0.4s ease-out;
}

.off {
    display:none;
}

.main {
    display: flex;
    flex-direction: column;
    padding: 120px 0;
    width: 1400px;
    margin: auto;
    gap: 60px;
}

.main h1 {
    text-align:center;
    padding: 20px 0;
    text-transform:uppercase;
    font-size:90px;
    color:white;
}

    .main h2 {
        padding: 40px 0px;
        font-size: 60px;
        color: white;
        z-index: 100;
    }

    .main p {
        color: white;
    }

    .main h3 {
        color: white;
    }

    a, button {
       cursor:pointer;
    }

.hero_textcontainer h2 {
    text-align:right;

}

.hero_textcontainer {
    flex-wrap:wrap;
    justify-content:center;

}

.hero_textcontainer ul h2 {
    text-align:left;

}


@media only screen and (max-width: 1400px ) {
    .main {
        width: 1200px;
    }

    .education_main {
        gap: 60px;
    }

    .heroBackground {
        text-align: center;
    }

}
  

@media only screen and (max-width: 1200px )  {
    .main {
        width:960px;
    }

    .educationAside {
        max-width: 400px;
    }

    .aboutContainer, .aboutContainerText {
        flex-wrap: wrap;
        justify-content: center;
        align-content: center;
        text-align: center;
    }

    .aboutButton, .aboutContainerDescription {
        justify-content: center;
        align-content: center;
        text-align: center;
    }

    .aboutImg {
        height: 450px;
        width:350px;
        margin: 0 80px;
        background-position: top center;
        margin-top: 100px;
        border-radius: 15px;
    }

}

@media only screen and (max-width: 992px ) {
    .main {
        width: 720px;
    }

    .dropping-texts ul li {
        margin:10px 0px;
    }
    .dropping-texts  {
        padding: 0;
    }

  

    .dropping-texts ul li {
    }

    .contact_details {
        width: 100%;
    }

    .form {
        width: 100%;
    }

    .education_main {
        padding:0;
    }

    .education_card {
        width: 250px;
    }

    .education_container {

        align-items:center;
    }

    .heroSubtitle {
        flex-wrap:wrap;
        justify-items:center;
        text-align:center;
    }





    .HomeButton {
        width: 100%;
        justify-content:center;
    }

    

    .heroSubtitle {
        justify-content: center;
        height: unset;
        flex-direction: column;
        align-items: center;
    }


}

@media only screen and (max-width: 850px ) {

    .heroSubtitle h2, .heroSubtitle li {
        font-size: 1rem;
    }

    .heroSubtitle svg, .home_li_before {
        width: 30px;
        height: 30px;
        transform: unset;
    }


    .HomeButton {
        margin-left: 0;
    }


 
    .button {
        padding: 10px 20px;
    }


    .imageExp {
        min-width: unset;
    }

    .main h1 {
     font-size: 45px;
    }

    .main h2 {
        font-size: 40px;
        text-align:center;
    }

    .main {
        width: 540px;
    }

    .educationAside {
        display: none;
    }

    .responsive {
        display: block;
    }

    .education_card {
        width: 100%;
    }

    .education_main {
        padding: 0 0px 0 80px;
    }

    .aboutDescription h4 {
       font-size: 20px;
    }

    .aboutDescription h2 {
        font-size: 30px;
    }

    .aboutDescription p {
        font-size: 15px;
    }

    .detailsOn {
        padding: 20px;
    }

        .detailsOn  ul{
            text-align: left;
        }
        
        .detailsOn  ul li{
            padding: 8px 0px;
        }

        .detailsOn h1 {
            font-size: 30px !important;
            padding:0;
        }

            .detailsOn h2 {
                font-size: 25px !important;
                padding: 0px 0px 40px 0px;
                text-transform: uppercase;
                color: #14f3db;
            }

    .descrText {
        align-items:center;
    }

    .container-reverse {
        flex-direction: column-reverse;
    }


    .container {
        flex-wrap:wrap;
        text-align:center;
        padding:0;
    }

    .descriptionRightTwo a, .descriptionRight a, .imageExp {
        margin-left: auto;
        margin-right: auto;
    }

    .imageExp {
        margin-left: auto;
        margin-right: auto;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .descriptionLeftTwo {
        min-width:300px;
    }

    .heroContent h1 {
        font-size: 540%;
    }

    .heroSubtitle, .dropping-texts {
        font-size: 25px;
    }

}

@media only screen and (max-width: 576px ) {
    .main {
        width: 85%;
    }

    footer ul {
        gap: 20px;
        font-size:12px;
    }

    .aboutImg {
        height: 250px;
    }

    .aboutContainer div {
        width: 100%;
    }

    .aboutButton {
        flex-wrap:wrap;
        gap:50px;
    }

    .aboutSocial {
        justify-content:center;
    }

    .aboutContainerText {
        gap : 30px;
    }

    .aboutDescription {
        padding-bottom: 20px;
    }

    .aboutImg {
        margin-top: 0px;
    }

    .heroContent h1 {
        font-size: 330%;
    }

    .heroSubtitle, .dropping-texts {
        font-size: 17px;
    }

    .pragmantic_button {
        display:none;
    }

    }


p {
    margin-bottom:unset;
}

a:hover {
    color: #14f3db;
    text-decoration:none;
}


body {
    font-family: "Segoe UI", Arial, sans-serif;
    line-height: 1.4;
    color: #444;
    background: #fff;
    height: 100vh;
}

canvas {
    display: block;
    vertical-align: bottom;
}
/* ---- tsparticles container ---- */
#tsparticles {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #323031;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
}

.github {
    bottom: 10px;
    right: 10px;
    position: fixed;
    border-radius: 10px;
    background: #fff;
    padding: 0 12px 6px 12px;
    border: 1px solid #000;
}

    .github a:hover,
    .github a:link,
    .github a:visited,
    .github a:active {
        color: #000;
        text-decoration: none;
    }

    .github img {
        height: 30px;
    }

    .github #gh-project {
        font-size: 20px;
        padding-left: 5px;
        font-weight: bold;
        vertical-align: bottom;
    }