a.navbar-brand {
  white-space: normal;
  text-align: center;
  word-break: break-all;
}

header {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 999;
}

.custom-select {
align-items:center;
justify-content:center;
align-content:center;
gap:10px;
font-size:16px;
}

.custom-select svg {
    font-size:150%;
    color : #14f3db;
}

.lang_select {
    -webkit-appearance: none; 
    -moz-appearance: none;
    appearance: none;
    border: none;
    background: none;
    font-family: inherit;
    font-size: inherit;
    color: inherit;
    padding: 0.5em 0em;
    outline: none;
    cursor: pointer;
    background-color: transparent;
    cursor:pointer;
    color:white;
}

    .lang_select option {
        margin:0;
        padding:0;
        border:0;
        text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
        color:black;
    }


.navbar {
    height: 90px;
    background: transparent;
    position: absolute;
    margin-top: 0;
    width: 100%;
    z-index: 999;
    display: flex;
    padding: 0 30px;
    align-content: center;
    justify-content: space-between;
    background-image: linear-gradient( to bottom, black, transparent);
}

.IconBack {
    background: #14f3db;
    padding: 15px;
    z-index:99;
    border-radius: 50%;
    display:flex;
}

.Icon {
    font-size:180%;
}

.navbar ul {
    align-items: center;
    align-self: center;
}

.menu {
    display:flex;
    gap:10px;
}

.menuOpen {
    animation: menuOpen 0.2s forwards;
    z-index: 99;
}

@keyframes menuOpen {
    from {
        opacity: 0;
        transform: translateX(+50px);
    }

    to {
        opacity: 1;
        transform: translateX(0px);
    }
}

.menuInit {
    display:none;
}

.menuClose {
    animation: menuClose 2s forwards;
}

@keyframes menuClose {
    from {
        opacity: 1;
        margin-top: 0px;
        transform: scale(1)
    }

    to {
        opacity: 0;
        margin-top: -100% ;
        transform:scale(0)
    }
}

.blur-background {
    position: absolute;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5); 
    backdrop-filter: blur(8px); 
    z-index: 50;
}

.overlay {
    height: auto;
    position: fixed; 
    z-index: 998; 
    right: -10px;
    top: 100px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 0 30px;
    max-height: calc( 100vh - 114px);
    z-index:1000;
}

.overlay ul {
    gap: 40px;
    position:fixed;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:flex-end;
    font-size:140%;
}

    .overlay ul li a {
        display: flex;
        gap: 20px;
        align-items: center;
    }

        .overlay ul li a:hover .IconBack {
            transform: scale(80%);
            transition: all 0.5s;
            color: white;
        }

        .overlay ul li a:hover .textMenu {
            animation: .5s textMenuOn forwards;
        }

.textMenu {
    opacity: 0;
    padding: 5px 20px;
    background: #14f3db;
    color: white;
    border-radius: 15px;
    width:max-content;
    text-align: center;
}

.textMenu, .IconBack {
    box-shadow: 10px 12px 12px rgba(0, 0, 0, 0.2);
}

@keyframes textMenuOn {
    from {
        transform: translateX(200px);
        opacity: 0;
    }

    to {
        transform: translateX(0px);
        opacity: 1;
    }
}


.nav-links {
    color: black;
    font-weight: bold;
}

.title {
    display: flex;
    flex-direction: column;
}

.subline {
    background-color: #14f3db;
    height: 10px;
    margin: auto;
    text-align: center;
    display: flex;
    width: 180px;
    color: #14f3db;
    padding: 0;
    animation: come 2s forwards;
    border-radius: 15px;
}


    .subline::before {
        width: 25px;
        content: "";
        height: 10px;
        background-color: black;
        animation: bar 2s infinite linear alternate;
        border-radius: 15px;
    }


@keyframes bar {
    from {
    }

    to {
        transform: translateX(+160px)
    }
}




@media (min-width: 768px) {
  html {
    font-size: 16px;
  }

}


@media (max-width: 768px) {

    .IconBack {
        background: #14f3db;
        padding: 13px;
        border-radius: 50%;
    }

    .Icon {
        font-size: 150%;
    }

}

.box-shadow {
  box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}



.pragmantic_button {
    background-color: #f1712e;
    padding: 7px 30px;
    border-radius: 20px;
    display: flex;
    cursor: pointer;
    gap: 5px;
}

.partners {
    background-color: grey;
    font-size: 11px;
    display: flex;
    align-self: center;
    padding: 2px 5px;
    border-radius: 3px;
    box-shadow: 3px 2px 1px #2B2B2B;
}

.pragmantic_button:hover {
    background-color: #ffa675;
    transform: translateX(10px);
    transition: 0.4s ease-out;
}

.pragmantic_button a:hover {
    color:white;
}

    .pragmantic_button:hover .partners {
        opacity: 0.8;
    }


.pragmantic_button a {
    color: white;
}

.rightMenu {
    align-items:center;
    display:flex;
    gap:50px;
}
