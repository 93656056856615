/* Services */

.containerSkills {
    display: flex;
    gap: 100px;
    flex-wrap: wrap;
    justify-content: space-between;
}

.skills { 
    display: flex;
    flex-direction: column;
    min-width: 40%;
}

.containerSkills h3 {
    width: 100%;
}


.descrText {
    display:flex;
    flex-direction:column;
    gap:10px;
}

.tools {
    display: flex;
    flex-wrap: wrap;
    max-width: 50%;
    justify-content: space-around;
}

    .tools img {
        max-width: 80%;
        max-height: 100%;
    }

.tools_card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    max-width: 150px;
    gap: 10px;
    text-align: center;
    background: white;
    padding: 10px 20px 0px 20px;
    border-radius: 15px;
    box-shadow: -0.3rem 0 1rem #000;
    border-bottom: 3px solid transparent;
    margin: 10px auto;
    transition: ease-in-out 0.5s;
}

.tools-table {
    display: flex;
    gap:20px;
    flex-wrap:wrap;
    flex-grow:1;
}

.tools_card p {
    color: black !important;
}

.tools_card:hover {
 cursor:pointer;
 transform:scale(90%);
}


.tools_card_img {
    width: 80%;
    max-height: 80px;
    margin:auto;
}

.
.tools_card:hover {
    border-bottom: 3px solid #14f3db;
}


.tools_card:hover ~ .card_card_img img {
    position: relative;
    left: 50px;
    transition: 0.4s ease-out;
}



.card:hover ~ .card_card_img img {
    position: relative;
    left: 50px;
    transition: 0.4s ease-out;
}

.tools_card_text {
    text-align: center;
    border-bottom: 3px solid transparent;
    padding-bottom: 10px;
}


.containerSkills h3 {
    width: 100%;
    margin-bottom: 30px;
    text-transform: uppercase;
    font-size: 180%;
}

.subskills {
    min-width: 40%;
}

.bar_skill {
    position: relative;
    border: 2px solid #14f3db;
    border-radius: 10px;
    margin: 10px 0 30px 0;
}

.details {
    width: 100%;
    display: flex;
    justify-content: space-between;
    text-transform: uppercase;
}


.bar_skill div {
    position: relative;
    width: 0;
    height: 9px;
    border-radius: 10px;
    background-color: #14f3db;
}



#html-bar-70 {
    animation: html-fill-70 2s forwards;
}

#html-bar-80 {
    animation: html-fill-80 2s forwards;
}


#html-bar-90 {
    animation: html-fill-90 2s forwards;
}

#html-bar-95 {
    animation: html-fill-95 2s forwards;
}

#html-bar-100 {
    animation: html-fill-100 2s forwards;
}


@keyframes html-fill-70 {
    100% {
        width: 70%;
    }
}

@keyframes html-fill-80 {
    100% {
        width: 80%;
    }
}

@keyframes html-fill-90 {
    100% {
        width: 90%;
    }
}

@keyframes html-fill-95 {
    100% {
        width: 95%;
    }
}

@keyframes html-fill-100 {
    100% {
        width: 100%;
    }
}



/* Services Section */

.ServicesContainer {
    display: flex;
    justify-content: center;
}

.sub_ServicesContainer {
    max-width: 1400px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 30px;
}

.exp-details-container {
    flex-direction:column;
    gap:100px;
}


    .ServiceItems {
        display: flex;
        box-shadow: -0.3rem 0 1rem #000;
        border-radius: 10px;
        flex-direction: column;
        gap: 10px;
        width: 300px;
        background-color: #1C1C1C;
        padding: 30px;
        height: 450px;
        flex-wrap: wrap;
        border: solid 5px transparent;
    }

    .ServiceItems li, .circle li {
        list-style: inside;
        color: white;
        padding: 5px 0px;
    }

        .ServiceItems li::before {
            background-color: white;
        }


.button-container{
    display:flex;
    gap:40px;
    flex-wrap:wrap;
}

.servicesIcons {
    font-size: 380%;
    color: #14f3db;
    margin: 10px 0px;
}

    .ServiceItems h3 {
        padding: 5px 0px;
        text-align:center;
        text-transform:uppercase;
    }

.ServiceItems svg {
    align-self:center;
}

    .ServiceItems:hover {
        border-bottom: solid 5px #14f3db;
    }

/* Previous Demain */

.experience {
    justify-content: center;
    top: 60px;
    display: flex;
    align-content: center;
    flex-wrap: wrap;
}

.card {
    display: flex;
    flex-direction: column;
    height: 700px;
    width: 350px;
    border-radius: 10px;
    box-shadow: -1rem 0 3rem #000;
    transition: 0.4s ease-out;
    position: relative;
    left: 0px;
    background: #1C1C1C;
    padding: 20px;
    position:relative;
    top:40px;
}

.card-open-icon {
    position: absolute;
    bottom: 10px;
    right: 10px;
    font-size: 20px;
    color: white;
    transition: 0.4s ease-out;
}

.card:hover .card-open-icon {
    color: #14f3db;
    font-size: 30px;
}

.card p {
    padding: 35px 0px 20px 0px;
}


    .card:hover {
        transform: translateY(-20px);
        transition: 0.4s ease-out;
        cursor:pointer;
    }

        .card:hover ~ .card {
            position: relative;
            left: 50px;
            transition: 0.4s ease-out;
        }

    .card h4 {
        color: white;
        font-size: 15px;
        font-style: italic;
        padding-top: 10px;
    }

    .card h3 {
        padding-top: 10px;
        text-transform:uppercase;
            }


.bar {
    position: absolute;
    top: 185px;
    left: 20px;
    height: 5px;
    width: 100px;
}

.emptybar {
    background-color: #2e3033;
    width: 130%;
    height: 100%;
}

.filledbar {
    position: absolute;
    top: 0px;
    z-index: 3;
    width: 0px;
    height: 100%;
    background: #14f3db;
    transition: 0.6s ease-out;
}

.filledbar_half {
    position: absolute;
    top: 0px;
    z-index: 3;
    width: 0px;
    height: 100%;
    background: #14f3db;
    transition: 0.6s ease-out;
}

.card:hover i {
    color: #14f3db;
    transition: 0.4s ease-out;
}


.card:hover .filledbar {
    width: 130%;
    transition: 0.4s ease-out;
}

.card:hover .filledbar_half {
    width: 90%;
    transition: 0.4s ease-out;
}

.circle {
    position: absolute;
    top: 180px;
    padding-right: 20px;
}

.stroke {
    stroke: white;
    stroke-dasharray: 360;
    stroke-dashoffset: 360;
    transition: 0.6s ease-out;
}



.card:hover .stroke {
    stroke-dashoffset: 100;
    transition: 0.6s ease-out;
}




.detailsOn {
    animation: detailsOnComing 1.5s forwards;
    overflow-x: hidden;
    display: block;
    top: 0;
    left: 0;
    flex-direction: column;
    position: fixed;
    z-index: 900;
    padding: 20px 80px;
    border-radius: 15px;
    box-shadow: -1rem 0 3rem #000;
    height: 100vh;
    overflow:hidden;
}

    .detailsOn container, .detailsOn h1, .detailsOn h2 {
        animation: detailsOnContent 1.5s forwards;
    }

@keyframes detailsOnContent {

    0% {
        opacity:0;
        width: 0px;
    }

    45% {
        opacity: 0;
        width: 90%;
    }

    99% {
        opacity: 1;
        width: 100%;
    }

}

    @keyframes detailsOnComing {
        0% {
            top: calc(100% + 100px);
            width: 0vw;
            overflow-y: hidden;
            z-index: 999;
        }

        20% {
            background: black;
            top: 0px;
            width: 0vw;
            overflow-y: hidden;
            z-index: 999;
        }

        50% {
            background: black;
            top: 0px;
            overflow-y: hidden;
            z-index: 999;
        }

    100% {
        overflow-y: auto;
        background: black;
        top: 0px;
        width: 100vw;
        z-index:999;
    }

    }


.detailsOff {
    display:none;
}

.Exit {
    position:relative;
    display:flex;
    justify-content:flex-end;
    height:60px;
    font-size:200%;
    cursor:pointer;
    width: 100%;
    color:white;
    margin-bottom:40px;
    z-index:99;
    animation : ExitOn forwards 1.5s;
}

.Exit svg{
    position:fixed;
}

@keyframes ExitOn {
    0% {
        opacity:0;
    }

    80% {
        opacity:0;
    }

    100% {
        opacity:1;
    }
}

.Exit:hover {
    color:#14f3db;
}

.container {
    display:flex;
    gap: 80px;
    align-items:center;
    padding: 50px 0;
   }

.container_reverse {
    flex-direction:row-reverse;
}



.container_image {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 400px; 
    height: auto;
    flex-basis: 40%; 
    flex-shrink: 0; 
    flex-grow: 1;
}

    .container_image img {
        width: 100%;
        height: auto; 
        object-fit: cover; 
        flex-shrink: 1;
        border-radius:15px;
    }


.container_descritpion {
    display: flex;
    flex-direction: column;
    align-items:flex-start;
    flex-wrap: wrap;
    color: white;
    flex-basis: 70%;
    flex-shrink: 1;
}



.detailsOn h1 {
    font-size: 70px !important;
}

.main .detailsOn h2 {
    font-size: 3rem ;
    padding:10px 0px;
}

.main .detailsOn p {
    font-size: 1.3rem;
    color: #14f3db;
    padding:20px 0;
}

.main .detailsOn h4 {
    font-size: 1.1rem;
    color: white;
    padding: 10px 0;
    font-style: italic;
}


.main .detailsOn ul li {
    color: white;
    list-style: circle;
    margin-left: 30px;
    padding: 5px 0;
}


.aoff {
    padding: 0 !important;
    border-radius: 0px !important;
    background: transparent !important;
    font-size:100% !important;
}


.detailsOn li, .detailsOn p {
    font-size: 18px;
}


.descriptionRightTwo li {
    list-style: circle;
}
.detailsOn .container_description {
    border-bottom: 2px solid white;
    padding: 40px 0px;
}


@media only screen and (max-width: 1200px ) {
    .containerSkills .skills div, .skills, .tools {
        width: 100%;
        max-width: unset;
        min-width: unset;
    }

    .containerSkills {
        justify-content: unset;
        width: 100%;
    }
}


@media only screen and (max-width: 992px ) {


    .container_description {
        height: fit-content;
        display:block;
    }


    .container_description {
        height: fit-content;
        display: block;
    }

    .main .detailsOn > * {
        text-align:center;
    }

    .container_image {
        margin: 0 auto;
    }


    .detailsOn .container {
        padding: 40px 0px ;
    }
}
